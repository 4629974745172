import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"

import AudioSection from "../components/audiosection"

const Audio = () => {
  return (
    <Layout>
      <Head title="Audio" />
      <AudioSection />
    </Layout>
  )
}

export default Audio
