import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as blogStyles from "./blog.module.scss"
import "../styles/index.scss"

import { OutboundLink } from "gatsby-plugin-google-analytics"

const MusicComponent = ({ song }) => {
  return (
    <div className={`${blogStyles.blogDiv}`}>
      <div className={`${blogStyles.blogPost}`}>
        <img src={song.image.file.url} alt={song.image.description} />
        <br />
        <OutboundLink
          href={song.musicUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="unusualButton">{song.message}</button>
        </OutboundLink>
      </div>
    </div>
  )
}


const LoosieComponent = ({ loosie }) => {
  return (
    <div className={`${blogStyles.blogDiv}`}>
      <div className={`${blogStyles.blogPost}`}>
        <h2>{loosie.audioFile.title}</h2>
        <audio controls>
          <source src={loosie.audioFile.file.url} type="audio/ogg" />
        </audio>
      </div>
    </div>
  )
}


const AudioSection = () => {
  const data = useStaticQuery(graphql`
    query {
      music: allContentfulMusicAndArt(sort: { fields: date, order: DESC }) {
        nodes {
          type: __typename
          date
          image {
            description
            file {
              url
            }
          }
          musicUrl
          message
        }
      }
      
      loosie: allContentfulAudioFile(sort: { fields: date, order: DESC }) {
        nodes {
          type: __typename
          date
          audioFile {
            title
            description
            file {
              url
            }
          }
        }
      }
    }
  `)

  // Creating the array of components
  const audioComponents = [
    ...data.music.nodes.map(m => ({
      date: m.date,
      component: <MusicComponent song={m} />,
    })),
    
    ...data.loosie.nodes.map(l => ({
      date: l.date,
      component: <LoosieComponent loosie={l} />,
    })),
    
  ]

  // Sort the components LIFO
  const sortedAudioComponents = audioComponents.sort(function compare(a, b) {
    var dateA = new Date(a.date)
    var dateB = new Date(b.date)
    return dateB - dateA
  })

  // Mapping out the array
  return <div>{sortedAudioComponents.map(c => c.component)}</div>
}

export default AudioSection
